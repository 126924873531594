<template>
  <div>
    <a
      v-if="!loading && url"
      :href="url"
      target="_blank"
      class="btn btn-primary"
    >Ver archivo en una nueva pestaña</a>

    <b-spinner
      v-if="loading"
      label="Spinning"
    />

    <div
      v-if="!loading && !url"
      class="alert alert-warning"
    >
      No se encontró el archivo multimedia
    </div>

  </div>
</template>
<script>
import Multimedia from '@/dc-it/models/Multimedia'
import { BSpinner } from 'bootstrap-vue'

export default {
  name: 'MultimediaViewerComponent',
  components: {
    BSpinner,
  },
  props: {
    multimedia: {
      type: Object,
      required: false,
      default: null,
    },
    multimediaId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      url: null,
      loading: false,
    }
  },
  mounted() {
    if (this.multimediaId && !this.multimedia && !Number.isNaN(this.multimediaId)) {
      this.loading = true
      Multimedia.where('id', '=', this.multimediaId).get().then(result => {
        if (result.length === 1) {
          this.url = result[0].getAttr('file_url')
        }
        this.loading = false
      })
    }

    if (this.multimedia) {
      this.url = this.multimedia.getAttr('file_url')
    }
  },
}
</script>

<style scoped>

</style>
